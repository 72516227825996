// Create global variables
var scale = vScale = "realtime";
var isSummary = 0;
var deviceType = "meter";
var analogSensorSymbol = [];
var reads;
var defaultDeviceType;
var defaultDevice;
var key_id;
var freeze_time;
var widget_status;
var devicePrefix;
var defaultTotalField = "";
var supportedDefaultedTotFields = [];
var selectTotalField;

// Layout/Fields
var selectLineGraphFields = "";
var selectBarGraphFields = "";
var selectCompare = "";
var selectTableFields = "";

let deviceAddress = "";

//Start main function
async function main() {
  _REQUEST = await checkInitialUrlParams();

  // Get & set initial data
  setInitialParams();

  hide_lines = [];
  default_show_lines = ["1", "2", "3", "4"];

  if (_REQUEST.get("hide_lines")) {
    hide_lines_param = _REQUEST.get("hide_lines").trim();
    hide_lines_param = hide_lines_param.substr(0, 4);
    hide_lines_arr = hide_lines_param.split("");
    hide_lines_arr.forEach(function (line) {
      if (default_show_lines.includes(line) && !hide_lines.includes(line)) {
        hide_lines.push(line);
      }
    });
  }

  if (_SUPPORTED_SCALES.hasOwnProperty(vScale)) {
    if (deviceType == "meter") {
      defaultFields = ["kWh_Tot"];
    } else if (deviceType == "iostack") {
      defaultFields = ["pulse_cnt_1"];
    }

    if (isSummary == 1)
      defaultTotalField =
        supportedDefaultedTotFields[defaultTotalField]["summary-bar"];
  } else {
    if (deviceType == "meter") {
      defaultFields = [
        "RMS_Watts_Tot",
        "RMS_Volts_Ln_1",
        "Amps_Ln_1",
        "Power_Factor_Ln_1",
      ];
    } else if (deviceType == "iostack") {
      defaultFields = [
        "Analog_In_1",
        "Analog_In_2",
        "Analog_In_3",
        "Analog_In_4",
      ];
    }
    defaultTotalField =
      supportedDefaultedTotFields[defaultTotalField]["realtime"];
  }

  fillLayoutTab();

  if (deviceType == "meter") {
    // Use Diff fields if graphtype is bar and report is for summary
    if (isSummary == 1 && _SUPPORTED_SCALES.hasOwnProperty(vScale)) {
      _ALL_SUMMARY_FIELDS = _ALL_SUMMARY_FIELDS_DIFF;
    } else {
      _ALL_SUMMARY_FIELDS = _ALL_SUMMARY_FIELDS_MAX;
    }
  } else if (deviceType == "iostack") {
    if (isSummary == 1 && _SUPPORTED_SCALES.hasOwnProperty(vScale)) {
      //Summary
      _ALL_SUMMARY_FIELDS = _ALL_SUMMARY_FIELDS_DIFF_IOSTACK;
    } else {
      //Realtime
      _ALL_SUMMARY_FIELDS = _ALL_SUMMARY_FIELDS_MAX_IOSTACK;
    }
  }

  if (_REQUEST.has("deviceAddress")) {
    defaultDevice = parseInt(_REQUEST.get("deviceAddress")).toString();
  }
  reads = setDefaultReads();
  defaultDeviceType = _REQUEST.get("deviceType").toLowerCase();

  selectTableFields =
    deviceType == "meter" ? "table_fields" : "io_table_fields";
  if (_REQUEST.get(selectTableFields))
    tableFields = _REQUEST.get(selectTableFields);

  if (_REQUEST.get(selectLineGraphFields))
    lineGraphFields = _REQUEST.get(selectLineGraphFields);
  if (selectBarGraphFields) barGraphField = _REQUEST.get(selectBarGraphFields);

  if (_REQUEST.has("graphtype")) _GRAPH_TYPE = _REQUEST.get("graphtype");

  if (_REQUEST.get("layout")) {
    layout = _REQUEST.get("layout");
  }

  if (isSummary == 1) {
    if (_REQUEST.has(selectBarGraphFields)) {
      graphFields = _REQUEST.get(selectBarGraphFields);
    } else {
      graphFields =
        deviceType === "meter"
          ? _DEFAULT_VALUES.meter.bar_graph_field
          : _DEFAULT_VALUES.iostack.io_bar_graph_field;
    }
  } else {
    if (_REQUEST.has(selectLineGraphFields)) {
      graphFields = _REQUEST.get(selectLineGraphFields);
    }
  }

  freeze_time = 120000; // freeze time in milliseconds 9999999;//
  ifvisible.setIdleDuration(1);

  ifvisible.on("statusChanged", function (e) {
    widget_status = e.status;
  });

  Array.prototype.removeDuplicates = function () {
    var temp = new Array();
    this.sort();
    for (i = 0; i < this.length; i++) {
      if (this[i] == this[i + 1]) {
        continue;
      }
      temp[temp.length] = this[i];
    }
    return temp;
  };

  Array.prototype.contains = function (a) {
    if (this.indexOf(a) > -1) {
      return true;
    }
    return false;
  };
}

document.addEventListener("DOMContentLoaded", async function () {
  await main();
  createTabPulse();
  createTabAnalog();
  createTabWire();
  initialAnalogSensorSymbol();
  loadAnalogDataCSV(); // Tab

  setLayout(_REQUEST.get("layout"));
  let checkCompare = _REQUEST.get(selectCompare);
  if (checkCompare == 1) {
    let head4Btn = $("#divRanges");
    head4Btn.addClass("head4btn_disable");
    let buttonText = $("#button_ranges");
    buttonText.addClass("not-active");
  }

  $("#dragdiv").draggable();
  $("#extremes_div").draggable();
  //$('#bookmarkpopup').draggable();
  $("#settings").draggable();

  var meter_span_text = defaultDevice + "/Real Time";
  if (isSummary == 1) {
    meter_span_text = defaultDevice + "/" + _SUPPORTED_SCALES[scale].desc;
  }
  $("#meter_span").html(meter_span_text);
  $("#meter_value").val(defaultDevice);
  $("#reads_amount").val(reads);
  $("#select_count").val(reads);

  if ($("#ranges_table").css("display") != "none") {
    var button_text = $("#toggle_button");
    button_text.text("Hide reads");
    $("#toggle_button").addClass("active");
  }

  if ($(".headbtndiv").css("display") == "none") {
    $("#toggleimage").attr("src", "./static/buttons/Down_Toggle.svg");
  } else {
    $("#toggleimage").attr("src", "./static/buttons/Up_Toggle.svg");
  }
  const toggleImage = document.getElementById("toggleimage");
  toggleImage.addEventListener("mouseenter", () => {
    toggleImage.style.filter = "brightness(0.5)";
  });

  toggleImage.addEventListener("mouseleave", () => {
    toggleImage.style.filter = "brightness(1)";
  });

  fillCountryOptions();
  getDevices();
  checkUrlParams();

  if (isSummary == 1) {
    chart = new Highcharts.Chart({
      accessibility: {
        enabled: false,
      },
      chart: {
        renderTo: "container",
        /*animation: {
          duration: $("#device_rate_value").text() * 1000 + 800,
        },*/
        animation: false,

        type: "column",
        events: {
          load: initialDataFill(reads),
        },
      },

      tooltip: {
        useHTML: true,
        formatter: function () {
          return barGraphTooltip(this);
        },
      },
      exporting: {
        enabled: false,
      },

      title: false,
      legend: false,

      plotOptions: {
        column: {
          borderWidth: 0, //,
          //groupPadding: 0.5
          states: {
            hover: {
              color: "#" + historycolor2,
            },
          },
          //, minPointLength: 2
        },
        series: {
          animation: {
            duration: 20,
          },
        },
      },

      xAxis: {
        type: "datetime",
        dateTimeLabelFormats: {
          // Override default format
          second: "%H:%M:%S",
          //minute: '%H:%M',
          hour: "%H:%M",
          day: "%d %b",
          week: "%d %b",
          month: "%b '%y",
        },
        //,maxZoom: 20 * 1000
      },

      yAxis: {
        reversed: false,
      },
      yAxis: [
        {
          id: "field1",
          min: 0,
          title: false,
          labels: {
            enabled: false,
          },
        },
      ],

      series: [
        {
          id: "series-0",
          pointWidth: 250,
          yAxis: "field1",
          color: "#" + historycolor1,
          negativeColor: "#" + historycolor2,
          data: [],
        },
      ],
    });
  } else {
    if (isEnableAreaGraph == 1) {
      chart = new Highcharts.Chart({
        accessibility: {
          enabled: false,
        },
        chart: {
          renderTo: "container",
          type: "area",
          animation: {
            duration: $("#device_rate_value").text() * 1000 + 800,
          },

          defaultSeriesType: "spline",
          events: {
            load: initialDataFill(reads),
          },
        },

        tooltip: {
          formatter: function () {
            return showToolTip(this);
          },
        },
        exporting: {
          enabled: false,
        },

        title: false,
        legend: false,
        plotOptions: {
          series: {
            animation: {
              duration: 2000,
            },

            point: {
              events: {
                mouseOver: function () {
                  let chart = this.series.chart;
                  var legend_fields = legendFields.toLowerCase().split("~");
                  if (chart.series[0] && chart.series[0].data) {
                    for (let i = 0; i < chart.series[0].data.length; i++) {
                      if (chart.series[0].data[i].x == this.x) {
                        $("#current_field1").css({ display: "none" });
                        $("#current_field2").css({ display: "none" });
                        $("#current_field3").css({ display: "none" });
                        $("#current_field4").css({ display: "none" });
                        if (chart.series[0].data[i] !== undefined)
                          var field1Value = getRangeValue(
                            legend_fields[0],
                            chart.series[0].data[i].y
                          );
                        if (chart.series[1].data[i] !== undefined)
                          var field2Value = getRangeValue(
                            legend_fields[1],
                            chart.series[1].data[i].y
                          );
                        if (chart.series[2].data[i] !== undefined)
                          var field3Value = getRangeValue(
                            legend_fields[2],
                            chart.series[2].data[i].y
                          );
                        if (chart.series[3].data[i] !== undefined)
                          var field4Value = getRangeValue(
                            legend_fields[3],
                            chart.series[3].data[i].y
                          );

                        $("#hover_current_field1").text(field1Value);
                        $("#hover_current_field2").text(field2Value);
                        $("#hover_current_field3").text(field3Value);
                        $("#hover_current_field4").text(field4Value);
                        $("#hover_current_field1").show();
                        $("#hover_current_field2").show();
                        $("#hover_current_field3").show();
                        $("#hover_current_field4").show();
                        break;
                      }
                    }
                  }
                },
              },
            },
            events: {
              mouseOut: function () {
                $("#current_field1").show();
                $("#current_field2").show();
                $("#current_field3").show();
                $("#current_field4").show();
                $("#hover_current_field1").css({ display: "none" });
                $("#hover_current_field2").css({ display: "none" });
                $("#hover_current_field3").css({ display: "none" });
                $("#hover_current_field4").css({ display: "none" });
              },
            },
          },
        },

        xAxis: {
          type: "datetime",
          //tickPixelInterval: 150,
          maxZoom: 20 * 1000,
        },
        yAxis: {
          labels: { enabled: false },
          reversed: true,
          reversedStacks: true,
          title: {
            text: "",
          },
          plotLines: [
            {
              value: 0,
              width: 1,
              color: "white",
            },
          ],
        },

        series: [
          {
            color: "#9FB318", // Green Line 9FB318 	97A43C
            negativeColor: "#B2C724",
            data: [],
            lineWidth: 1,
            //yAxis: 'field4',
            marker: {
              enabled: false,
            },
            zIndex: 4,
          },
          {
            color: "#FF8000", // Orange Line FF8000 EF9625
            negativeColor: "#F3B739",
            data: [],
            lineWidth: 1,
            //yAxis: 'field3',
            marker: {
              enabled: false,
            },
            zIndex: 3,
          },
          {
            color: "#D400FF", // Purple Line  7E58A3
            negativeColor: "#B67AF4",
            data: [],
            lineWidth: 1,
            //yAxis: 'field2',
            marker: {
              enabled: false,
            },
            zIndex: 2,
          },
          {
            color: "#0080FF", // Blue Line   1F97C9
            negativeColor: "#5AD1ED",
            data: [],
            lineWidth: 1,
            //yAxis: 'field1',
            marker: {
              enabled: false,
            },
            zIndex: 1,
          },
        ],
      });
    } else if (compare == 1) {
      selectMinY = deviceType == "meter" ? "compare_min_y" : "io_compare_min_y";
      selectMaxY = deviceType == "meter" ? "compare_max_y" : "io_compare_max_y";
      let setMinY = _REQUEST.get(selectMinY)
        ? parseFloat(_REQUEST.get(selectMinY))
        : null;
      let setMaxY = _REQUEST.get(selectMaxY)
        ? parseFloat(_REQUEST.get(selectMaxY))
        : null;
      if (isNaN(setMinY)) {
        setMinY = null;
      }

      if (isNaN(setMaxY)) {
        setMaxY = null;
      }

      chart = new Highcharts.Chart({
        accessibility: {
          enabled: false,
        },
        chart: {
          renderTo: "container",
          /*animation: {
            duration: $("#device_rate_value").text() * 1000 + 800,
          },*/
          animation: false,

          defaultSeriesType: "spline",
          events: {
            load: initialDataFill(reads),
          },
        },

        tooltip: {
          formatter: function () {
            return showToolTip(this);
          },
        },
        exporting: {
          enabled: false,
        },
        title: false,
        legend: false,
        plotOptions: {
          series: {
            //compare: 'percent',
            animation: false,

            point: {
              events: {
                mouseOver: function () {
                  let chart = this.series.chart;
                  var legend_fields = legendFields.toLowerCase().split("~");
                  if (chart.series[0] && chart.series[0].data) {
                    for (let i = 0; i < chart.series[0].data.length; i++) {
                      if (chart.series[0].data[i].x == this.x) {
                        $("#current_field1").css({ display: "none" });
                        $("#current_field2").css({ display: "none" });
                        $("#current_field3").css({ display: "none" });
                        $("#current_field4").css({ display: "none" });
                        if (chart.series[0].data[i] !== undefined)
                          var field1Value = getRangeValue(
                            legend_fields[0],
                            chart.series[0].data[i].y
                          );
                        if (chart.series[1].data[i] !== undefined)
                          var field2Value = getRangeValue(
                            legend_fields[1],
                            chart.series[1].data[i].y
                          );
                        if (chart.series[2].data[i] !== undefined)
                          var field3Value = getRangeValue(
                            legend_fields[2],
                            chart.series[2].data[i].y
                          );
                        if (chart.series[3].data[i] !== undefined)
                          var field4Value = getRangeValue(
                            legend_fields[3],
                            chart.series[3].data[i].y
                          );
                        $("#hover_current_field1").text(field1Value);
                        $("#hover_current_field2").text(field2Value);
                        $("#hover_current_field3").text(field3Value);
                        $("#hover_current_field4").text(field4Value);
                        $("#hover_current_field1").show();
                        $("#hover_current_field2").show();
                        $("#hover_current_field3").show();
                        $("#hover_current_field4").show();
                        break;
                      }
                    }
                  }
                },
              },
            },
            events: {
              mouseOut: function () {
                $("#current_field1").show();
                $("#current_field2").show();
                $("#current_field3").show();
                $("#current_field4").show();
                $("#hover_current_field1").css({ display: "none" });
                $("#hover_current_field2").css({ display: "none" });
                $("#hover_current_field3").css({ display: "none" });
                $("#hover_current_field4").css({ display: "none" });
              },
            },
          },
        },
        // Compare (Relative View)
        xAxis: {
          type: "datetime",
          //tickPixelInterval: 150,
          maxZoom: 20 * 1000,
        },
        yAxis: {
          min: setMinY,
          max: setMaxY,
          labels: { enabled: true },
          title: {
            text: "",
          },
          plotLines: [
            {
              //compare mode
              value: 0,
              width: 2,
              color: "red",
            },
          ],
        },

        series: [
          {
            color: "#97A43C", // Green Line
            data: [],
            lineWidth: 1,
            //yAxis: 'field4',
            marker: {
              enabled: false,
            },
            zIndex: 4,
          },
          {
            color: "#FFC92B", // Orange Line
            data: [],
            lineWidth: 1,
            //yAxis: 'field3',
            marker: {
              enabled: false,
            },
            zIndex: 3,
          },
          {
            color: "#E566FF", // Purple Line
            data: [],
            lineWidth: 1,
            //yAxis: 'field2',
            marker: {
              enabled: false,
            },
            zIndex: 2,
          },
          {
            color: "#5FCBE8", // Blue Line
            data: [],
            lineWidth: 1,
            //yAxis: 'field1',
            marker: {
              enabled: false,
            },
            zIndex: 1,
          },
        ],
      });
    } else {
      chart = new Highcharts.chart({
        accessibility: {
          enabled: false,
        },
        chart: {
          renderTo: "container",
          animation: {
            duration: $("#device_rate_value").text() * 1000 + 800,
          },

          defaultSeriesType: "spline",
          events: {
            load: initialDataFill(reads),
          },
        },

        tooltip: {
          formatter: function () {
            return showToolTip(this);
          },
        },

        exporting: {
          enabled: false,
        },

        title: false,
        legend: false,

        plotOptions: {
          series: {
            animation: {
              duration: 2000,
            },

            point: {
              events: {
                mouseOver: function () {
                  let chart = this.series.chart;
                  var legend_fields = legendFields.toLowerCase().split("~");

                  if (chart.series[0] && chart.series[0].data) {
                    for (let i = 0; i < chart.series[0].data.length; i++) {
                      if (chart.series[0].data[i].x == this.x) {
                        $("#current_field1").css({ display: "none" });
                        $("#current_field2").css({ display: "none" });
                        $("#current_field3").css({ display: "none" });
                        $("#current_field4").css({ display: "none" });

                        if (chart.series[0].data[i] !== undefined)
                          var field1Value = getRangeValue(
                            legend_fields[0],
                            chart.series[0].data[i].y
                          );
                        if (chart.series[1].data[i] !== undefined)
                          var field2Value = getRangeValue(
                            legend_fields[1],
                            chart.series[1].data[i].y
                          );
                        if (chart.series[2].data[i] !== undefined)
                          var field3Value = getRangeValue(
                            legend_fields[2],
                            chart.series[2].data[i].y
                          );
                        if (chart.series[3].data[i] !== undefined)
                          var field4Value = getRangeValue(
                            legend_fields[3],
                            chart.series[3].data[i].y
                          );
                        $("#hover_current_field1").text(field1Value);
                        $("#hover_current_field2").text(field2Value);
                        $("#hover_current_field3").text(field3Value);
                        $("#hover_current_field4").text(field4Value);
                        $("#hover_current_field1").show();
                        $("#hover_current_field2").show();
                        $("#hover_current_field3").show();
                        $("#hover_current_field4").show();
                        break;
                      }
                    }
                  }
                },
              },
            },
            events: {
              mouseOut: function () {
                $("#current_field1").show();
                $("#current_field2").show();
                $("#current_field3").show();
                $("#current_field4").show();
                $("#hover_current_field1").css({ display: "none" });
                $("#hover_current_field2").css({ display: "none" });
                $("#hover_current_field3").css({ display: "none" });
                $("#hover_current_field4").css({ display: "none" });
              },
            },
          },
        },

        xAxis: {
          type: "datetime",
          //tickPixelInterval: 150,
          maxZoom: 20 * 1000,
        },
        yAxis: [
          {
            id: "field4", // Green Line
            minPadding: 0.1,
            maxPadding: 0.1,
            max: setMinMax(0),
            title: false,
            labels: {
              enabled: false,
            },
            //opposite: true,
            minRange: 0.6, //1.0
          },
          {
            id: "field3", // Orange Line
            minPadding: 0.4,
            maxPadding: 0.4,
            max: setMinMax(1),
            labels: {
              enabled: false,
            },
            title: false,
            minRange: 0.6,
          },
          {
            id: "field2", // Purple Line
            minPadding: 0.2,
            maxPadding: 0.2,
            max: setMinMax(2),
            title: false,
            labels: {
              enabled: false,
            },
            //opposite: true,
            minRange: 0.4,
          },

          {
            id: "field1", // Blue Line
            minPadding: 0.3,
            maxPadding: 0.3,
            max: setMinMax(3),
            title: false,
            labels: {
              enabled: false,
            },
            minRange: 0.8,
          },
        ],
        series: [
          {
            color: "#97A43C", // Green Line
            data: [],
            lineWidth: 1,
            yAxis: "field4",
            marker: {
              enabled: false,
            },
          },
          {
            color: "#FFC92B", // Orange Line
            data: [],
            lineWidth: 1,
            yAxis: "field3",
            marker: {
              enabled: false,
            },
          },
          {
            color: "#E566FF", // Purple Line
            data: [],
            lineWidth: 1,
            yAxis: "field2",
            marker: {
              enabled: false,
            },
          },
          {
            color: "#5FCBE8", // Blue Line
            data: [],
            lineWidth: 1,
            yAxis: "field1",
            marker: {
              enabled: false,
            },
          },
        ],
      });
    }
  }

  requestData(0, 0, false);
  //initialDataFill(reads);

  $("#togglearrow").click(async function () {
    let imgsrc = $("#toggleimage").attr("src");
    let tmplayout_fields_value = layout == -1 ? null : layout.split("~");

    if (
      tmplayout_fields_value != null &&
      tmplayout_fields_value.contains("hide_all")
    ) {
      tmplayout_fields_value.splice(
        tmplayout_fields_value.indexOf("hide_all"),
        1
      );
    }

    if (imgsrc == "./static/buttons/Down_Toggle.svg") {
      if (
        tmplayout_fields_value != null &&
        !tmplayout_fields_value.contains("show_buttons")
      ) {
        tmplayout_fields_value.push("show_buttons");
      } else if (tmplayout_fields_value == null) {
        if (_REQUEST.get("graphtype") == "bar") {
          tmplayout_fields_value = ["show_buttons"];
        } else {
          tmplayout_fields_value = ["show_buttons", "show_legend"];
        }
      }
    } else {
      if (
        tmplayout_fields_value != null &&
        tmplayout_fields_value.contains("show_buttons")
      ) {
        tmplayout_fields_value.splice(
          tmplayout_fields_value.indexOf("show_buttons"),
          1
        );
      } else if (tmplayout_fields_value == null) {
        tmplayout_fields_value = ["show_legend"];
      }
    }

    if (tmplayout_fields_value.length == 0) {
      layout = "hide_all";
    } else {
      layout = tmplayout_fields_value.join("~");
    }
    if (!inIframe()) {
      await buildURL();
    }

    $("#buttons-bar").slideToggle("slow", function () {
      var imgsrc = $("#toggleimage").attr("src");
      if (imgsrc == "./static/buttons/Down_Toggle.svg") {

        if (vScale == "" || vScale == "realtime" || vScale === null) {
          $(
            "#button_ranges, #toggle_button, #button_table,.headbtndiv,.head3btndiv,.head4btndiv"
          ).css({ display: "block" });
        } else {
          $(
            "#button_15min, #button_hour, #button_day, #button_week, #button_month, .headbtndiv,.head2btndiv,.head3btndiv"
          ).css({ display: "block" });
        }
        $("#toggleimage").attr("src", "./static/buttons/Up_Toggle.svg");
      } else {
        $("#toggleimage").attr("src", "./static/buttons/Down_Toggle.svg");
      }
    });
  });

  /***************************************** Toggle function for below buttons ends *****************************/

  $("#button_ranges").click(async function () {
    var button_text = $("#button_ranges");

    let tmplayout_fields_value = layout == -1 ? null : layout.split("~");

    if (
      tmplayout_fields_value != null &&
      tmplayout_fields_value.contains("hide_all")
    ) {
      tmplayout_fields_value.splice(
        tmplayout_fields_value.indexOf("hide_all"),
        1
      );
    }

    if (button_text.text() == "Show range") {
      if (
        tmplayout_fields_value != null &&
        !tmplayout_fields_value.contains("show_range")
      ) {
        tmplayout_fields_value.push("show_range");
      } else if (tmplayout_fields_value == null) {
        if (_REQUEST.get("graphtype") == "bar") {
          tmplayout_fields_value = ["show_buttons"];
        } else {
          tmplayout_fields_value = [
            "show_buttons",
            "show_legend",
            "show_range",
          ];
        }
      }
    } else {
      if (
        tmplayout_fields_value != null &&
        tmplayout_fields_value.contains("show_range")
      ) {
        tmplayout_fields_value.splice(
          tmplayout_fields_value.indexOf("show_range"),
          1
        );
      } else if (tmplayout_fields_value == null) {
        tmplayout_fields_value = ["show_buttons", "show_legend"];
      }
    }

    if (tmplayout_fields_value.length == 0) {
      layout = "hide_all";
    } else {
      layout = tmplayout_fields_value.join("~");
    }

    if (!inIframe()) {
      await buildURL();
    }

    update_ranges();
    $("#extremes_div").toggle();

    if (button_text.text() == "Show range") {
      button_text.text("Hide range");
      $("#button_ranges").addClass("active");
    } else {
      button_text.text("Show range");
      $("#button_ranges").removeClass("active");
    }
  });

  $("#button_settings,#close-box").click(function () {
    $("#settings").toggle();
    createurl();
  });

  $(document).keyup(function (e) {
    if (
      e.key === "Escape" &&
      document.querySelector("#settings").style.display == "block"
    ) {
      document.querySelector("#settings").style.position = "relative";
      document.querySelector("#settings").style.display = "none";
    }
  });

  $("#printing").click(function () {
    window.print();
  });

  $("#bookmarkimg,#close_box1").click(function () {
    updateBookmark();

    $("#bookmarkpopup").toggle();
    if (!hasFlash()) {
      $("#markbutton").prop("value", "Select");
    }
  });

  $("#copytext, #markbutton").click(function () {
    copycontent("#copytext");
  });

  $("#params_text").click(function () {
    copycontent("#params_text");
  });

  $("#button_update_settings").hover(
    function () {
      $("#button_update_settings").fadeTo("normal", 1);
    },
    function () {
      $("#button_update_settings").fadeTo("normal", 0.6);
    }
  );

  $("#button_update_settings").click(function () {
    var reads = $("#reads_amount").val();

    getDevices(true);
    $("#key_value_reserve").val($("#key_value").val());

    $.wait = function (time) {
      return $.Deferred(function (dfd) {
        // resolve() calls all of the registered
        // 'done' callbacks (registered using then())
        setTimeout(dfd.resolve, time);
      }).promise();
    };

    $.wait(2000).then(function () {
      initialDataFill(reads);
    });
  });

  $("#button_table").click(async function () {
    var button_text = $("#button_table");
    let tmplayout_fields_value = layout == -1 ? null : layout.split("~");

    if (
      tmplayout_fields_value != null &&
      tmplayout_fields_value.contains("hide_all")
    ) {
      tmplayout_fields_value.splice(
        tmplayout_fields_value.indexOf("hide_all"),
        1
      );
    }

    if (button_text.text() == "Show table") {
      if (
        tmplayout_fields_value != null &&
        !tmplayout_fields_value.contains("show_table")
      ) {
        tmplayout_fields_value.push("show_table");
      } else if (tmplayout_fields_value == null) {
        if (_REQUEST.get("graphtype") == "bar") {
          tmplayout_fields_value = ["show_buttons"];
        } else {
          tmplayout_fields_value = [
            "show_buttons",
            "show_legend",
            "show_table",
          ];
        }
      }
    } else {
      if (
        tmplayout_fields_value != null &&
        tmplayout_fields_value.contains("show_table")
      ) {
        tmplayout_fields_value.splice(
          tmplayout_fields_value.indexOf("show_table"),
          1
        );
      } else if (tmplayout_fields_value == null) {
        tmplayout_fields_value = ["show_buttons", "show_legend"];
      }
    }

    if (tmplayout_fields_value.length == 0) {
      layout = "hide_all";
    } else {
      layout = tmplayout_fields_value.join("~");
    }

    if (!inIframe()) {
      await buildURL();
    }

    $("#info_table").toggle();
    var button_text = $("#button_table");

    if (button_text.text() == "Show table") {
      showTable();
    } else {
      button_text.text("Show table");
      $("#info_table").css({ display: "none" });
      $("#button_table").removeClass("active");
    }
  });

  $("#toggle_button").click(async function () {
    $("#ranges_table").css({ display: "block" });
    var button_text = $("#toggle_button");
    let tmplayout_fields_value = layout == -1 ? null : layout.split("~");

    if (
      tmplayout_fields_value != null &&
      tmplayout_fields_value.contains("hide_all")
    ) {
      tmplayout_fields_value.splice(
        tmplayout_fields_value.indexOf("hide_all"),
        1
      );
    }

    if (button_text.text() == "Show reads") {
      if (
        tmplayout_fields_value != null &&
        !tmplayout_fields_value.contains("show_legend")
      ) {
        tmplayout_fields_value.push("show_legend");
      } else if (tmplayout_fields_value == null) {
        if (_REQUEST.get("graphtype") == "bar") {
          tmplayout_fields_value = ["show_buttons"];
        } else {
          tmplayout_fields_value = ["show_buttons", "show_legend"];
        }
      }
    } else {
      if (
        tmplayout_fields_value != null &&
        tmplayout_fields_value.contains("show_legend")
      ) {
        tmplayout_fields_value.splice(
          tmplayout_fields_value.indexOf("show_legend"),
          1
        );
      } else if (tmplayout_fields_value == null) {
        tmplayout_fields_value = ["show_buttons"];
      }
    }

    if (tmplayout_fields_value.length == 0) {
      layout = "hide_all";
    } else {
      layout = tmplayout_fields_value.join("~");
    }

    if (!inIframe()) {
      await buildURL();
    }

    if (button_text.text() == "Show reads") {
      $("#dragdiv").css({ display: "block" });
      button_text.text("Hide reads");
      $("#toggle_button").addClass("active");
    } else {
      $("#dragdiv").css({ display: "none" });
      button_text.text("Show reads");
      $("#toggle_button").removeClass("active");
    }
  });

  // Field1 button action
  $("#button_1, .ranges_field_1").click(function () {
    var series = chart.series[0];

    if (series.visible) {
      hide_lines.push("1");

      changeHideParamValue(hide_lines.join(""));
    } else {
      var index = hide_lines.indexOf("1");
      if (index > -1) {
        hide_lines.splice(index, 1);
      }
      changeHideParamValue(hide_lines.join(""));
    }
  });

  // Field2 button action
  $("#button_2, .ranges_field_2").click(function () {
    var series = chart.series[1];
    if (series.visible) {
      hide_lines.push("2");

      changeHideParamValue(hide_lines.join(""));
    } else {
      var index = hide_lines.indexOf("2");
      if (index > -1) {
        hide_lines.splice(index, 1);
      }

      changeHideParamValue(hide_lines.join(""));
    }
  });

  // Field3 button action
  $("#button_3, .ranges_field_3").click(function () {
    var series = chart.series[2];

    if (series.visible) {
      hide_lines.push("3");

      changeHideParamValue(hide_lines.join(""));
    } else {
      var index = hide_lines.indexOf("3");
      if (index > -1) {
        hide_lines.splice(index, 1);
      }

      changeHideParamValue(hide_lines.join(""));
    }
  });

  // Field4 button action
  $("#button_4, .ranges_field_4").click(function () {
    var series = chart.series[3];
    if (series.visible) {
      hide_lines.push("4");

      changeHideParamValue(hide_lines.join(""));
    } else {
      var index = hide_lines.indexOf("4");

      if (index > -1) {
        hide_lines.splice(index, 1);
      }

      changeHideParamValue(hide_lines.join(""));
    }
  });

  const selectedWireSensor = document.querySelector("#select_wire_sensor");
  actualWireSelected = selectedWireSensor.value;
  selectedWireSensor.addEventListener("change", function (event) {
    event.preventDefault();
    document.querySelector(`#div_${actualWireSelected}`).style.display = "none";
    document.querySelector(`#div_${this.value}`).style.display = "block";
    actualWireSelected = this.value;
  });

  function setSelectedIndex(s, valsearch) {
    // Loop through all the items in drop down list
    for (i = 0; i < s.options.length; i++) {
      if (s.options[i].value == valsearch) {
        // Item is found. Set its property and exit
        s.options[i].selected = true;
        break;
      }
    }
    return;
  }

  var newreads = _REQUEST.get("reads");

  window.onload = setSelectedIndex(
    document.getElementById("select_count"),
    newreads
  );

  if (isSummary == 1) {
    $("#select_scale option[value=realtime]").remove();
  }

  const headBtnDiv = document.querySelector("#headbtndiv");

  if (
    scale == "realtime" ||
    scale == "" ||
    scale == null
  ) {
    headBtnDiv.innerHTML =
      '\
        <div class="headbtn"><a href="#" class="active" onclick="changeURLParamValue(\'scale\',\'realtime\');" id="button_realtime">Real Time</a></div> \
        <div class="headbtn" id="divHistory"><a href="#" onclick="changeURLParamValue(\'scale\',\'15\');" id="button_history">History</a></div>';
  } else {
    headBtnDiv.innerHTML =
      '\
            <div class="headbtn"><a href="#" onclick="changeURLParamValue(\'scale\',\'realtime\');" id="button_realtime">Real Time</a></div>\
            <div class="headbtn" id="divHistory"><a href="#" class="active" onclick="changeURLParamValue(\'scale\',\'15\');" id="button_history">History</a></div>';
  }

  const head2BtnDiv = document.querySelector("#head2btndiv");

  let head2BtnDivHTML = "";
  if (scale == "15") {
    head2BtnDivHTML +=
      '<div class="head2btn"><a class="active" href="#" onclick="changeURLParamValue(\'scale\',\'15\');" id="button_15min">15 Minute</a></div>';
  } else {
    head2BtnDivHTML +=
      '<div class="head2btn"><a href="#" onclick="changeURLParamValue(\'scale\',\'15\');" id="button_15min">15 Minute</a></div>';
  }
  if (scale == "hr") {
    head2BtnDivHTML +=
      '<div class="head2btn"><a class="active" href="#" onclick="changeURLParamValue(\'scale\',\'hr\');" id="button_hour">Hour</a></div>';
  } else {
    head2BtnDivHTML +=
      '<div class="head2btn"><a href="#" onclick="changeURLParamValue(\'scale\',\'hr\');" id="button_hour">Hour</a></div>';
  }
  if (scale == "dy") {
    head2BtnDivHTML +=
      '<div class="head2btn"><a class="active" href="#" onclick="changeURLParamValue(\'scale\',\'dy\');" id="button_day">Day</a></div>';
  } else {
    head2BtnDivHTML +=
      '<div class="head2btn"><a href="#" onclick="changeURLParamValue(\'scale\',\'dy\');" id="button_day">Day</a></div>';
  }
  if (scale == "wk") {
    head2BtnDivHTML +=
      '<div class="head2btn"><a class="active" href="#" onclick="changeURLParamValue(\'scale\',\'wk\');" id="button_week">Week</a></div>';
  } else {
    head2BtnDivHTML +=
      '<div class="head2btn"><a href="#" onclick="changeURLParamValue(\'scale\',\'wk\');" id="button_week">Week</a></div>';
  }
  if (scale == "mo") {
    head2BtnDivHTML +=
      '<div class="head2btn"><a class="active" href="#" onclick="changeURLParamValue(\'scale\',\'mo\');" id="button_month">Month</a></div>';
  } else {
    head2BtnDivHTML +=
      '<div class="head2btn"><a href="#" onclick="changeURLParamValue(\'scale\',\'mo\');" id="button_month">Month</a></div>';
  }
  head2BtnDiv.innerHTML = head2BtnDivHTML;

  //
  const button1 = document.querySelector("#button1");
  const button2 = document.querySelector("#button2");
  const button3 = document.querySelector("#button3");
  button1.innerHTML = setLoadReadsJSByScaleAndType("LOW", 1);
  button2.innerHTML = setLoadReadsJSByScaleAndType("MEDIUM", 1);
  button3.innerHTML = setLoadReadsJSByScaleAndType("HIGH", 1);

  /*************************/
  /*      Engine Button    */
  /*************************/
  // Engine > Tab 1: General > Graph Type
  graph_options = ["Real Time", "History"];

  selected_graph_value = isSummary;

  const selectGraph = document.querySelector("#select_graph");
  graph_options.forEach((value, index) => {
    let option = document.createElement("option");
    option.text = value;
    option.value = index;
    if (selected_graph_value == index) {
      option.setAttribute("selected", "");
    }
    selectGraph.add(option);
  });

  // Engine > Tab 1: General > Scale
  let scaleLabel = document.querySelector("#scale_label");
  let options_scale = {
    15: "15 Minute",
    hr: "Hour",
    dy: "Day",
    wk: "Week",
    mo: "Month",
  };
  const selectScaleElement = document.createElement("select");
  selectScaleElement.setAttribute("id", "select_scale");
  if (isSummary == 1) {
    selectScaleElement.setAttribute("onchange", "change_select();");
  }
  scaleLabel.after(selectScaleElement);
  const selectScale = document.querySelector("#select_scale");
  for (let index in options_scale) {
    let option = document.createElement("option");
    option.text = options_scale[index];
    option.value = index;
    if (scale == index) {
      option.setAttribute("selected", "");
    }
    selectScale.add(option);
  }

  // Engine > Tab 1: General > Reads
  options_reads = {
    100: "100",
    500: "500",
    1000: "1000",
  };

  if (_REQUEST.has("reads") && _REQUEST.get("reads") != "undefined") {
    selected_count_value = _REQUEST.get("reads");
  } else {
    selected_count_value = setDefaultReads();
  }

  selected_count_value = checkReads(selected_count_value);

  const selectCount = document.querySelector("#select_count");

  for (let key in options_reads) {
    let option = document.createElement("option");

    if (selected_count_value == key) {
      option.value = key;
      option.text = key;
      option.setAttribute("selected", "");
    } else {
      option.value = key;
      option.text = key;
    }
    selectCount.add(option);
  }

  // Engine > Tab 2: Pulse/kWh > Total kWh
  //fillPulseTab(true);

  // Engine > Tab 3: Analog
  //Load CSV
  // I'm calling the function loadAnalogDataCSV() after the Doc is ready, to avoid async issues
  // Engine > Tab 4: Wire
  fillWireTab();
  // Engine > Tab 5: Layout/Fields
  // Engine > Tab 6: Config Params
  
  const tmpUrlParams = new URLSearchParams(window.location.search);

  if (tmpUrlParams.has("q")){
    document.querySelector("#li_tab_params").style.display = "block"
  }

  //fillTotalField(deviceTypeParam)

  const historyColor = document.querySelector("#history_color");

  for (let key in OPTIONS_COLOR) {
    let option = document.createElement("option");
    option.value = key;
    option.text = OPTIONS_COLOR[key];
    if (_REQUEST.get("historical") == key) {
      option.setAttribute("selected", "");
    }
    historyColor.add(option);
  }

  // Engine > Tab 7: Release Notes
  var releaseFile = new XMLHttpRequest();
  const widget_version = "5.0";
  const url =
    location.protocol +
    "//" +
    location.host +
    location.pathname +
    "release.txt?v=" +
    widget_version;
  releaseFile.open("GET", url, true);
  releaseFile.send();
  releaseFile.onreadystatechange = function () {
    if (releaseFile.readyState == 4 && releaseFile.status == 200) {
      releaseText = releaseFile.responseText;
      releaseText = releaseText.split("\r").join("<br>");
      releaseText = releaseText.split("\n").join("<br>");
      releaseText = releaseText.split("*").join("<li>");
      document.querySelector("#release_text").innerHTML = releaseText;
    }
  };

  const widgetVersion = document.querySelector("#widget_version");
  widgetVersion.innerText = widget_version;

  /************************** Assigning value to select read box on ready ******************************/
  if (isSummary == 1) {
    document.getElementById("select_scale").style.display = "block";
    document.getElementById("scale_label").style.display = "block";
  } else {
    document.getElementById("select_scale").style.display = "none";
    document.getElementById("scale_label").style.display = "none";
  }

  if (scale == "15") {
    var select = $("#select_count");
    select
      .empty()
      .append(
        '<option value="48">12 hour</option><option value="96">1 day</option><option value="192">2 days</option>'
      );
  } else if (scale == "hr") {
    var select = $("#select_count");
    select
      .empty()
      .append(
        '<option value="48">2 days</option><option value="120">5 days</option><option value="168">1 week</option>'
      );
  } else if (scale == "dy") {
    var select = $("#select_count");
    select
      .empty()
      .append(
        '<option value="7">1 week</option><option value="14">2 week</option><option value="30">1 month</option>'
      );
  } else if (scale == "wk") {
    var select = $("#select_count");
    select
      .empty()
      .append(
        '<option value="13">3 month</option><option value="26">6 month</option><option value="52">1 year</option>'
      );
  } else if (scale == "mo") {
    var select = $("#select_count");
    select
      .empty()
      .append(
        '<option value="6">6 month</option><option value="12">1 year</option><option value="24">2 year</option>'
      );
  }

  /*****************************************************************************************************/

  /************************** function to reload page on window resize ********************************/
  $(window).resize(function () {
    var width = $(window).width();
    if (width > 766) {
      if (window.location.search.indexOf("graphtype=bar") > -1) {
        var current_url_is = window.location.href;
        index = current_url_is.indexOf("#");
        if (index > 0) {
          current_url_is = current_url_is.substring(0, index);
        }
        location.replace(current_url_is);
      }
    }
  });

  $.fn.mobile = function () {
    var offset = null;
    var start = function (e) {
      var orig = e.originalEvent;
      var pos = $(this).position();
      offset = {
        x: orig.changedTouches[0].pageX - pos.left,
        y: orig.changedTouches[0].pageY - pos.top,
      };
    };
    var moveMe = function (e) {
      e.preventDefault();
      var orig = e.originalEvent;
      $(this).css({
        top: orig.changedTouches[0].pageY - offset.y,
        left: orig.changedTouches[0].pageX - offset.x,
      });
    };
    this.bind("touchstart", start);
    this.bind("touchmove", moveMe);
  };

  $("#bookmarkpopup").mobile();
  $(".popupwindow").mobile();
  $("#dragdiv").mobile();
  $("#extremes_div").mobile();
});

$(function () {
  $("#dragdiv").draggable({
    start: function (event, ui) {
      $(this).data("dragging", true);
      $("#button_2").off("click");
      $("#button_3").off("click");
      $("#button_4").off("click");
      $("#button_1").off("click");
    },
    stop: function (event, ui) {
      setTimeout(function () {
        $(event.target).data("dragging", false);

        $("#button_1").click(function () {
          var series = chart.series[0];
          if (series.visible) {
            series.hide();
            $("#button_1").css("background", "#b2c724");
            $(".ranges_field_1").css("background", "#b2c724");
          } else {
            showBarGraphByID(0);
            series.show();
            $("#button_1").css("background", "#9FB318");
            $(".ranges_field_1").css("background", "#9FB318");
          }
        });

        $("#button_2").click(function () {
          var series = chart.series[1];
          if (series.visible) {
            series.hide();
            $("#button_2").css("background", "#F3B739");
            $(".ranges_field_2").css("background", "#F3B739");
          } else {
            showBarGraphByID(1);
            series.show();
            $("#button_2").css("background", "#ff8000");
            $(".ranges_field_2").css("background", "#ff8000");
          }
        });

        $("#button_3").click(function () {
          var series = chart.series[2];
          if (series.visible) {
            series.hide();
            $("#button_3").css("background", "#b67af4");
            $(".ranges_field_3").css("background", "#b67af4");
          } else {
            showBarGraphByID(2);
            series.show();
            $("#button_3").css("background", "#D400FF");
            $(".ranges_field_3").css("background", "#D400FF");
          }
        });
        $("#button_4").click(function () {
          var series = chart.series[3];
          if (series.visible) {
            series.hide();
            $("#button_4").css("background", "#5ad1ed");
            $(".ranges_field_4").css("background", "#5ad1ed");
          } else {
            showBarGraphByID(3);
            series.show();
            $("#button_4").css("background", "#0080ff");
            $(".ranges_field_4").css("background", "#0080ff");
          }
        });
      }, 1);
    },
  });
});

$(function () {
  $("#extremes_div").draggable({
    start: function (event, ui) {
      $(this).data("dragging", true);
      $(".ranges_field_1").off("click");
      $(".ranges_field_2").off("click");
      $(".ranges_field_3").off("click");
      $(".ranges_field_4").off("click");
    },
    stop: function (event, ui) {
      setTimeout(function () {
        $(event.target).data("dragging", false);

        $(".ranges_field_1").click(function () {
          var series = chart.series[0];
          if (series.visible) {
            series.hide();
            $("#button_1").css("background", "#b2c724");
            $(".ranges_field_1").css("background", "#b2c724");
          } else {
            showBarGraphByID(0);
            series.show();
            $("#button_1").css("background", "#9FB318");
            $(".ranges_field_1").css("background", "#9FB318");
          }
        });

        $(".ranges_field_2").click(function () {
          var series = chart.series[1];
          if (series.visible) {
            series.hide();
            $("#button_2").css("background", "#F3B739");
            $(".ranges_field_2").css("background", "#F3B739");
          } else {
            showBarGraphByID(1);
            series.show();
            $("#button_2").css("background", "#ff8000");
            $(".ranges_field_2").css("background", "#ff8000");
          }
        });

        $(".ranges_field_3").click(function () {
          var series = chart.series[2];
          if (series.visible) {
            series.hide();
            $("#button_3").css("background", "#b67af4");
            $(".ranges_field_3").css("background", "#b67af4");
          } else {
            showBarGraphByID(2);
            series.show();
            $("#button_3").css("background", "#D400FF");
            $(".ranges_field_3").css("background", "#D400FF");
          }
        });
        $(".ranges_field_4").click(function () {
          var series = chart.series[3];
          if (series.visible) {
            series.hide();
            $("#button_4").css("background", "#5ad1ed");
            $(".ranges_field_4").css("background", "#5ad1ed");
          } else {
            showBarGraphByID(3);
            series.show();
            $("#button_4").css("background", "#0080ff");
            $(".ranges_field_4").css("background", "#0080ff");
          }
        });
      }, 1);
    },
  });
});
